<template>
  <div  style="font-size: medium">
    <app-navigation :title="$t('triggers.select-trigger-type')" :backButtonText="$t('navigation.back')"></app-navigation>



    <div style="margin: 20px; background-color: #fff; border: 1px solid #eeeeee; border-radius: 10px; padding: 25px">

      {{$t('triggers.trigger-type-info')}}

      <div class="when-then" @click="handleDeviceOrientedClick">
        <i style="flex: 1" class="fa fa-plug fa-fw"></i>

        <div style="flex: 20">
          <div>{{$t('triggers.trigger-type-device')}}</div>
          <div style="margin-top: 5px; font-style: italic">{{$t('triggers.trigger-type-device-info')}}</div>
        </div>

        <i style="flex: 1" class="fa fa-chevron-right"></i>

      </div>


      <div class="when-then" @click="handleScheduleOrientedClick">

        <i style="flex: 1" class="fa fa-calendar-alt fa-fw"></i>

        <div style="flex: 20">
          <div>{{$t('triggers.trigger-type-schedule')}}</div>
          <div style="margin-top: 5px; font-style: italic">{{$t('triggers.trigger-type-schedule-info')}}</div>
        </div>

        <i style="flex: 1" class="fa fa-chevron-right"></i>

      </div>
    </div>



  </div>
</template>

<script>
  import Navigation from '@/components/Navigation'
  export default {
    name: "TriggerTypes",
    components: {
      'app-navigation': Navigation,
    },

    methods: {
      handleDeviceOrientedClick: function(){
        this.$router.push({name: 'When'})
      },
      handleScheduleOrientedClick: function(){
        this.$router.push({name: 'WhenSchedule'})
      },
    }
  }
</script>

<style scoped>
  .when-then {
    margin-top: 25px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: #545c64;
  }
  .when-then:hover {
    background-color: #333333;
  }
</style>