var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "font-size": "medium" } },
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("triggers.select-trigger-type"),
          backButtonText: _vm.$t("navigation.back")
        }
      }),
      _c(
        "div",
        {
          staticStyle: {
            margin: "20px",
            "background-color": "#fff",
            border: "1px solid #eeeeee",
            "border-radius": "10px",
            padding: "25px"
          }
        },
        [
          _vm._v(
            "\n\n    " +
              _vm._s(_vm.$t("triggers.trigger-type-info")) +
              "\n\n    "
          ),
          _c(
            "div",
            {
              staticClass: "when-then",
              on: { click: _vm.handleDeviceOrientedClick }
            },
            [
              _c("i", {
                staticClass: "fa fa-plug fa-fw",
                staticStyle: { flex: "1" }
              }),
              _c("div", { staticStyle: { flex: "20" } }, [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("triggers.trigger-type-device")))
                ]),
                _c(
                  "div",
                  {
                    staticStyle: { "margin-top": "5px", "font-style": "italic" }
                  },
                  [_vm._v(_vm._s(_vm.$t("triggers.trigger-type-device-info")))]
                )
              ]),
              _c("i", {
                staticClass: "fa fa-chevron-right",
                staticStyle: { flex: "1" }
              })
            ]
          ),
          _c(
            "div",
            {
              staticClass: "when-then",
              on: { click: _vm.handleScheduleOrientedClick }
            },
            [
              _c("i", {
                staticClass: "fa fa-calendar-alt fa-fw",
                staticStyle: { flex: "1" }
              }),
              _c("div", { staticStyle: { flex: "20" } }, [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("triggers.trigger-type-schedule")))
                ]),
                _c(
                  "div",
                  {
                    staticStyle: { "margin-top": "5px", "font-style": "italic" }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("triggers.trigger-type-schedule-info"))
                    )
                  ]
                )
              ]),
              _c("i", {
                staticClass: "fa fa-chevron-right",
                staticStyle: { flex: "1" }
              })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }